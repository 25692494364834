<template lang="html">
  <div class="card card-primary card-outline">
    <div class="card-header">
      <h3 class="card-title">修改密码</h3>
      <div class="card-tools mr-0">
      </div>
    </div>

    <!-- /.box-header -->
    <div class="card-body">
      <div class="row">
        <div class="col-sm-6 col-md-4 col-lg-4">
          <div class="form-group" v-if="hasPassword">
            <label for="currentPassword">旧密码</label>
            <input type="password" class="form-control" :class="{ 'is-invalid': $v.currentPassword.$error }" id="currentPassword" v-model.trim="$v.currentPassword.$model" placeholder="旧密码">
            <div class="invalid-feedback">
              <span v-if="!$v.currentPassword.required">请输入密码</span>
              <span v-if="!$v.currentPassword.minLength">至少 {{$v.currentPassword.$params.minLength.min}} 个字符</span>
              <span v-if="!$v.currentPassword.maxLength">最多 {{$v.currentPassword.$params.maxLength.max}} 个字符</span>
            </div>
          </div>
          <div class="form-group">
            <label for="newPassword">新密码</label>
            <input type="password" class="form-control" :class="{ 'is-invalid': $v.newPassword.$error }" id="newPassword" v-model.trim="$v.newPassword.$model" placeholder="新密码">
            <div class="invalid-feedback">
              <span v-if="!$v.newPassword.required">请输入密码</span>
              <span v-if="!$v.newPassword.isPassword">至少8位长度，必须包含大写字母、小写字母、数字及特殊字符</span>
              <span v-if="!$v.newPassword.minLength">至少 {{$v.newPassword.$params.minLength.min}} 个字符</span>
              <span v-if="!$v.newPassword.maxLength">最多 {{$v.newPassword.$params.maxLength.max}} 个字符</span>
            </div>
          </div>
          <div class="form-group">
            <label for="confirmPassword">确认新密码</label>
            <input type="password" class="form-control" :class="{ 'is-invalid': $v.confirmPassword.$error }" id="nickName" v-model.trim="$v.confirmPassword.$model" placeholder="确认新密码">
            <div class="invalid-feedback">
              <span v-if="!$v.confirmPassword.sameAsPassword">与新密码不匹配</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- /.box-body -->

    <div class="card-footer">
      <router-link class="btn btn-default mr-1" :to="{ name: 'Profile'}">取消</router-link>
      <button class="btn btn-primary" :disabled="!canSubmit||$v.newPassword.$error" @click="submit">确定</button>
    </div>    <!-- Loading (remove the following to stop the loading)-->
    <div class="overlay" v-if="updateing">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <!-- end loading -->

  </div>
</template>

<script>
  import { required, minLength, maxLength, sameAs, helpers } from 'vuelidate/lib/validators'
  const isPassword = helpers.regex('isPassword', /^.*(?=.{8,30})(?=.*\d)(?=.*[A-Z]{1,})(?=.*[a-z]{1,})(?=.*[!@#$%^&*?\(\)]).*$/)

  export default {
    data() {
      return {
        updateing: false,
        hasPassword: null,
        currentPassword: null,
        newPassword: null,
        confirmPassword: null,
        timer: null,
        waitTime: 0
      }
    },
    computed: {
      canSubmit() {
        if (this.newPassword == this.confirmPassword) {
          return true
        }
        return false
      }
    },
    validations: {
      currentPassword: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(30),
      },
      newPassword: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(30),
        isPassword,
      },
      confirmPassword: {
        sameAsPassword: sameAs('newPassword')
      },
    },
    created() {
      this.loadData()
    },
    methods: {
      loadData() {
        var url = "/Api/My/Identity/HasPassword"
        var params = {
        }

        this.$axios.get(url, { params: params })
          .then((response) => {
            console.log(response)
            this.hasPassword = response.data
          }).catch((error) => {
            console.log(error)
          })

      },
      submit() {
        var url = '/Api/My/Identity/SetPassword';
        var data = {
          newPassword: this.newPassword,
          confirmPassword: this.confirmPassword
        };
        if (this.hasPassword) {
          url = '/Api/My/Identity/ChangePassword';
          data = {
            currentPassword: this.currentPassword,
            newPassword: this.newPassword,
            confirmPassword: this.confirmPassword
          };
        }
        this.$axios.post(url, data).then((res) => {
          if (res.data && res.data.Succeeded) {
            this.hasPassword = true
            this.$message({
              message: '密码修改成功',
              type: 'info',
              zIndex: 9999,
            })
            this.$router.push({ name: 'Profile' })
          } else {
            this.$message({
              message: '密码修改错误',
              type: 'error',
              zIndex: 9999,
              //showClose: true
            })
            for (var i in res.data.Errors) {
              console.info(res.data.Errors[i])
              this.$message({
                message: res.data.Errors[i].Description,
                type: 'error',
                zIndex: 9999,
                //showClose: true
              })
            }

          }
        }).catch((err) => {
          console.error(err)
        })
      },
    },
    components: {
    },
  }
</script>

<style lang="css">
  .top_container {
    width: 100%;
    position: relative;
    display: inline-block;
    max-width: 300px;
  }

  .dummy {
    margin-top: 100%;
  }

  .cropper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
</style>
